import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Platform } from '@phx-husky/fetch-lds';
import { useUpdateOTLds } from '@phx-husky/use-update-ot-lds';
import { parseLds } from 'shared/regexp';

import { Container, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Typography from 'common/Typography';
import Breadcrumbs from 'components/Breadcrumbs';

import './LdsPage.scss';

const LdsPage: FC<{ data: LdsPageTypes.LdsPageSiteTypes }> = ({
  data: {
    lds: { urls, seo, body, pageName, versionId, platform, businessId },
  },
}) => {
  const { title, keywords, description } = seo;
  const [ldsContent, setLdsContent] = useState<string[]>([]);

  useEffect(() => {
    setLdsContent(body.match(parseLds) || []);
  }, [body]);

  const { updatedLdsContent, isCheckingLdsVersion } = useUpdateOTLds({
    baseUrl: process.env.GATSBY_OT_LDS_BASE_URL as string,
    businessId,
    versionId,
    platform,
  });

  const [bodyParsed, titleParsed, contentParsed] = ldsContent;
  const content = platform === Platform.OneTrust ? body : contentParsed;

  const heading =
    bodyParsed && platform === Platform.Health ? (
      <div className="lds-page__heading">
        <Typography
          variant="heading2"
          weight="medium"
          dangerouslySetInnerHTML={titleParsed.toUpperCase()}
        />
      </div>
    ) : null;

  const renderContent =
    updatedLdsContent || content ? (
      <Typography
        variant="paragraph1"
        dangerouslySetInnerHTML={updatedLdsContent ?? content}
        customClass="lds-page__content"
      />
    ) : null;

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo, pageName }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      {!isCheckingLdsVersion && (
        <Container fluid className="lds-page">
          <Breadcrumbs />
          <Container element="section">
            {heading}
            {renderContent}
          </Container>
        </Container>
      )}
    </Layout>
  );
};

export const query = graphql`
  query LdsPageQuery($url: String!) {
    lds(url: { eq: $url }) {
      ...LdsPageFragment
    }
  }
`;

export default LdsPage;
